<template>
    <div class="informational">
        <HeroBlog v-if="parentType === 'Blog'" v-bind="parent.value?.heroBlog" :title="parent.value?.title" />
        <HeroBasic
            v-else-if="parentType === 'Program'"
            v-bind="programsProjectsHeroProps"
            :eyebrow="$t('Programs')"
            :hasHeaderOffset="true"
        />
        <HeroBasic
            v-else-if="parentType === 'Project'"
            v-bind="programsProjectsHeroProps"
            :logo="programsProjectsHero?.logo?.url"
            :eyebrow="$t('Projects')"
            :hasHeaderOffset="true"
            :heading="title"
        />
        <HeroBasic v-else :heroImageUrl="informationalHeroImage?.url" :heading="title" :hasHeaderOffset="true" />
        <SubnavBlock
            :navigationLinks="pageSubNav?.navigationLinks"
            :socialNetworks="pageSubNav?.socialNetworks"
            :button="subNav?.button"
            :pageLanguage="pageLanguage"
        />
        <template v-if="parentType === 'Blog' || parentType === 'Program'">
            <section class="top-title">
                <div class="inner">
                    <div class="heading-block">
                        <Typography class="title" :variant="`h1-display`" :as="h1">{{ title }}</Typography>
                    </div>
                </div>
            </section>
        </template>
        <template v-if="streamTiered">
            <StreamTierFeed :streamTiered="streamTiered" />
        </template>
    </div>
</template>

<script setup>
const { pageLanguage } = usePageDataStore();
const props = defineProps({
    title: {
        type: String,
    },
    parent: {
        type: Object,
    },
    streamTiered: {
        type: Array,
    },
    hero: {
        type: Object,
    },
    dek: {
        type: Object,
    },
    subNav: {
        type: Object,
    },
    path: {
        type: String,
    },
    locale: {
        type: String,
        default: '',
    },
});

const parentType = props.parent?.value?.__typename;

const informationalHeroImage =
    parentType === 'Informational' && props.parent?.value?.hero?.image
        ? props.parent?.value?.hero?.image
        : props.hero?.image;

const programsProjectsHero = props.parent?.value?.heroProgramsProjects;

const programsProjectsHeroProps = {
    heroImageUrl: programsProjectsHero?.heroImage?.url,

    heading: props.parent?.value?.title,
    isTall: true,
};

const GTMCenter = useGTM();
const GTMLang = props.locale;
onMounted(() => {
    window.dataLayer.push({
        Center: GTMCenter.GTMCenter,
        Doctype: 'Informational',
        Expert: '',
        'Taxonomy Tags Program': '',
        'Taxonomy Special Project': '',
        'Taxonomy Tags Regions': '',
        'Taxonomy Tags Topics': '',
        'Page Language': GTMLang,
        event: 'new_data_layer',
    });
});

const pageSubNav =
    props.subNav?.navigationLinks || props.subNav?.socialNetworks ? props.subNav : props.parent?.value?.subNav;
</script>
<style lang="scss" scoped>
.top-title {
    padding: 6.4rem 0;
    .inner {
        padding-left: var(--page-margin);
        padding-right: var(--page-margin);
        margin: 0 auto;
        max-width: 144rem;
        width: 100%;

        .h1-display {
            text-align: center;
            display: block;

            @include media-query(mobile) {
                text-align: left;
            }
        }
    }
}
</style>
